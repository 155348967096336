<template>
  <div id="app">
    <Home></Home>
  </div>
</template>

<script>
import Home from "./pages/home.vue";

export default {
  name: "App",
  components: {
    Home
  }
};
</script>

<style lang='less'>
html {
  height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 14px;
  -webkit-overflow-scrolling: touch;
}

body {
  margin: 0;
  font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  color: rgba(0, 0, 0, 0.65);
  height: 100%;
  font-size: 14px;
  line-height: 1.5;
}

a {
  outline: none;
  text-decoration: none;
  background-color: transparent;
  color: #999;
  &:hover, &:focus, &:active {
    outline: 0;
    text-decoration: none;
  }
}
</style>
