export default {
  "应用集成": {
    "cn": "",
    "en": "Application Integration",
  },
  "开发平台": {
    "cn": "",
    "en": "Platform Development ",
  },
  "服务体系": {
    "cn": "",
    "en": "Service System",
  },
  "PaaS 应用开放平台": {
    "cn": "",
    "en": "Open-sourced PaaS",
  },
  "轻松实现企业数字化定制": {
    "cn": "",
    "en": "Free to enterprises digital customization",
  },
  "轻量，稳定，快速，高效": {
    "cn": "",
    "en": "Light & Stable & Swift & Efficient",
  },
  "丰富的开放 API ，覆盖各类应用业务场景": {
    "cn": "",
    "en": "Prolific and open API to cover different application business scenarios",
  },
  "数据集成，一站式应用交付": {
    "cn": "",
    "en": "Data integration for one-stop application delivery",
  },
  "开放": {
    "cn": "",
    "en": "Openness",
  },
  "150 多个 API ，多语言 SDK，持续集成": {
    "cn": "",
    "en": "150+ API and multi-language SDK for never-ending integration",
  },
  "连接": {
    "cn": "",
    "en": "Connection",
  },
  "统一数据接口，连接内外，应用无缝集成": {
    "cn": "",
    "en": "Uniform data interface and seamless in-out application integration",
  },
  "平台": {
    "cn": "",
    "en": "Platform",
  },
  "一站式应用构建平台，快速上线私有业务": {
    "cn": "",
    "en": "One-stop application platform for fast go-live private business",
  },
  "涵盖企业所有的业务需求，可根据您的需求特点，灵活组合多类应用": {
    "cn": "",
    "en": "Seamlessly connect all business demands and deliver personalized experiences by flexibly combining multiple applications.",
  },
  "开放平台": {
    "cn": "",
    "en": "Open Platform",
  },
  "完善的底层架构，低成本助力企业数字化定制": {
    "cn": "",
    "en": "Holistic infrastructure for low-cost enterprises digital customization",
  },
  "镜像仓库": {
    "cn": "",
    "en": "Repository Mirroring",
  },
  "可视化部署": {
    "cn": "",
    "en": "Visual deployment",
  },
  "灰度发布": {
    "cn": "",
    "en": "Gated Launch",
  },
  "代码安全扫描": {
    "cn": "",
    "en": "Secure Code Scanning",
  },
  "平台发布": {
    "cn": "",
    "en": "Platform Launch",
  },
  "前端框架": {
    "cn": "",
    "en": "Front-end Framework",
  },
  "项目管理方案": {
    "cn": "",
    "en": "Project Management Solutions",
  },
  "应用接入SDK": {
    "cn": "",
    "en": "Access Application SDK",
  },
  "可视化开发工具": {
    "cn": "",
    "en": "Visual Development Tools",
  },
  "大数据&人工智能": {
    "cn": "",
    "en": "Big Data & AI",
  },
  "智能推荐": {
    "cn": "",
    "en": "Intelligent Recommendation",
  },
  "智能选址": {
    "cn": "",
    "en": "Intelligent Site Selection",
  },
  "企业画像": {
    "cn": "",
    "en": "Enterprise Profiling",
  },
  "图像分析": {
    "cn": "",
    "en": "Image Analysis",
  },
  "语音识别": {
    "cn": "",
    "en": "Voice Recognition",
  },
  "监控和日志": {
    "cn": "",
    "en": "Log & Monitor",
  },
  "系统服务监控": {
    "cn": "",
    "en": "Monitoring System Services",
  },
  "日志存储和分析": {
    "cn": "",
    "en": "Log Storage and Analysis",
  },
  "系统服务日志": {
    "cn": "",
    "en": "System and Service Logs",
  },
  "异常通知": {
    "cn": "",
    "en": "Exception Notification",
  },
  "安全": {
    "cn": "",
    "en": "Security",
  },
  "DDoS防护": {
    "cn": "",
    "en": "DDoS Protection",
  },
  "Web应用防火墙": {
    "cn": "",
    "en": "Web Application Firewall",
  },
  "数据加密服务": {
    "cn": "",
    "en": "Data Encryption Services",
  },
  "样本智能分析平台": {
    "cn": "",
    "en": "Sample Intelligent Analysis Platform",
  },
  "云防火墙": {
    "cn": "",
    "en": "Cloud Firewall",
  },
  "网络": {
    "cn": "",
    "en": "Network",
  },
  "主干道网络保障通讯": {
    "cn": "",
    "en": "Backbone Network to Secure Comms",
  },
  "kubernetes集群": {
    "cn": "",
    "en": "Kubernetes Clusters",
  },
  "存储": {
    "cn": "",
    "en": "Storage",
  },
  "多种不同类型数据": {
    "cn": "",
    "en": "Multiple Data Types",
  },
  "kubernets 容器云": {
    "cn": "",
    "en": "kubernetes Cloud Container",
  },
  "aPaaS 平台": {
    "cn": "",
    "en": "aPaaS Platform",
  },
  "一站式DevOps": {
    "cn": "",
    "en": "One-stop DevOps",
  },
  "提升研发效能": {
    "cn": "",
    "en": "Improve R&D Performance",
  },
  "高可靠，搞可用的": {
    "cn": "",
    "en": "High Reliability High Availability",
  },
  "开放API服务": {
    "cn": "",
    "en": "Open API Services",
  },
  "具备成熟的网站桂花策略与开发技术，为客户提供全方位高端网站建设服务。": {
    "cn": "",
    "en": "Well-developed website layout strategies and development technologies to deliver high grade website construction service.",
  },
  "网站建设": {
    "cn": "",
    "en": "Website Construction",
  },
  "以HTML5技术为基础，实现移动端自动兼容与游览，衍生品牌和用户之间的触点。": {
    "cn": "",
    "en": "Automate mobile compatibility and touring and quickly adapt to customer demands based on HTML5.",
  },
  "HRML5响应式网站": {
    "cn": "",
    "en": "Responsive HRML5 Website",
  },
  "将数据做成简洁、生动的图标，凭借丰富的交互效果，洞察具价值的信息和规律。": {
    "cn": "",
    "en": "Turn data into simple and vivid icons, with full interactive effects to gain insights into valuable information and regularities.",
  },
  "大数据可视化": {
    "cn": "",
    "en": "Big Data Visualization",
  },
  "电子商务解决方案": {
    "cn": "",
    "en": "Ecommerce Solution",
  },
  "以企业商化的战略发展为出发点，提供多种商业模式的电商平台建设服务。": {
    "cn": "",
    "en": "Provide ecommerce platform construction services for various business models in view of the development of enterprise commercialization.",
  },
  "平台整体解决方案": {
    "cn": "",
    "en": "Platform Total Solution",
  },
  "根据品牌及业务需求，链接行业上下游产业资源，打造一体化的服务平台。": {
    "cn": "",
    "en": "Connect up-down stream industry resources to create an integrated service platform based on brand and business demands.",
  },
  "友好砍价": {
    "cn": "",
    "en": "Bargaining",
  },
  "分销应用": {
    "cn": "",
    "en": "Dropshipping",
  },
  "商品搭售": {
    "cn": "",
    "en": "Tie-in Sales",
  },
  "大数据分析": {
    "cn": "",
    "en": "Big Data Analytics",
  },
  "客服工具": {
    "cn": "",
    "en": "Customer Service",
  },
  "运营工具": {
    "cn": "",
    "en": "Operation Tools",
  },
  "二维码生成": {
    "cn": "",
    "en": "QR Code Generator",
  },
  "生意精灵": {
    "cn": "",
    "en": "Business Wizard",
  },
  "快递打印": {
    "cn": "",
    "en": "Express Printing",
  },
  "辅助工具": {
    "cn": "",
    "en": "Helpers",
  },
  "全渠道ERP": {
    "cn": "",
    "en": "Omnichannel ERP",
  },
  "营销工具": {
    "cn": "",
    "en": "Marketing Tools",
  },
}