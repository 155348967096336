<template>
  <div class="home-box">
    <div class="home-title flex-center">
      <div class="center-box">
        <div class="left">
          <img src="../assets/images/home/logo.png" />
          <img
            style="margin-left: 10px; height: 25px"
            src="../assets/images/home/TXT Singapore.png"
          />
        </div>
        <div class="right">
          <div class="tag-box">
            <div class="item" @click="goTo(1)">
              <a href="#Application">
                <div class="text">{{ $t("应用集成") }}</div>
                <div class="line-box" v-show="tag == 1">
                  <div class="line"></div>
                </div>
              </a>
            </div>
            <div class="item" @click="goTo(2)">
              <a href="#Development">
                <div class="text">{{ $t("开发平台") }}</div>
                <div class="line-box" v-show="tag == 2">
                  <div class="line"></div>
                </div>
              </a>
            </div>
            <div class="item" @click="goTo(3)">
              <a href="#Service">
                <div class="text">{{ $t("服务体系") }}</div>
                <div class="line-box" v-show="tag == 3">
                  <div class="line"></div>
                </div>
              </a>
            </div>
          </div>
          <div class="lang-box">
            <div class="lang-top" @click="langShow = true">
              <img src="../assets/images/home/earth.png" />
              <span class="text">{{ lang.toUpperCase() }}</span>
              <img src="../assets/images/home/path.png" />
            </div>
            <div v-if="langShow" class="check-box">
              <div :class="{ color: lang == 'cn' }" @click="langChange('cn')">
                中文
              </div>
              <div :class="{ color: lang == 'en' }" @click="langChange('en')">
                English
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-box flex-center">
      <div class="bg-center">
        <div class="left">
          <div class="left-box">
            <div class="top">
              <div>{{ $t("PaaS 应用开放平台") }}</div>
              <div>{{ $t("轻松实现企业数字化定制") }}</div>
            </div>
            <div class="bottom">
              <div>{{ $t("轻量，稳定，快速，高效") }}</div>
              <div>{{ $t("丰富的开放 API ，覆盖各类应用业务场景") }}</div>
              <div>{{ $t("数据集成，一站式应用交付") }}</div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="bg">
            <img src="../assets/images/home/bg.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="content-box">
      <div style="width: 1200px">
        <div class="f-box">
          <div class="item">
            <div class="flex-center">
              <img src="../assets/images/home/f1.png" />
            </div>
            <div class="text1">{{ $t("开放") }}</div>
            <div class="text2">
              {{ $t("150 多个 API ，多语言 SDK，持续集成") }}
            </div>
          </div>
          <div class="item">
            <div class="flex-center">
              <img src="../assets/images/home/f2.png" />
            </div>
            <div class="text1">{{ $t("连接") }}</div>
            <div class="text2">
              {{ $t("统一数据接口，连接内外，应用无缝集成") }}
            </div>
          </div>
          <div class="item">
            <div class="flex-center">
              <img src="../assets/images/home/f3.png" />
            </div>
            <div class="text1">{{ $t("平台") }}</div>
            <div class="text2">
              {{ $t("一站式应用构建平台，快速上线私有业务") }}
            </div>
          </div>
        </div>
        <div class="title" id="Application">{{ $t("应用集成") }}</div>
        <div class="title-text">
          {{
            $t("涵盖企业所有的业务需求，可根据您的需求特点，灵活组合多类应用")
          }}
        </div>
        <div class="imgs-box">
          <div class="items">
            <div class="top">
              <div class="item">
                <div class="flex-center">
                  <div class="roll">
                    <img src="../assets/images/home/img1.png" />
                  </div>
                </div>
                <div class="text1">{{ $t("友好砍价") }}</div>
              </div>
              <div class="item">
                <div class="flex-center">
                  <div class="roll">
                    <img src="../assets/images/home/img2.png" />
                  </div>
                </div>
                <div class="text1">{{ $t("分销应用") }}</div>
              </div>
              <div class="item">
                <div class="flex-center">
                  <div class="roll">
                    <img src="../assets/images/home/img3.png" />
                  </div>
                </div>
                <div class="text1">{{ $t("商品搭售") }}</div>
              </div>
            </div>
            <div class="bottom">{{ $t("营销工具") }}</div>
          </div>
          <div class="items blue">
            <div class="top">
              <div class="item">
                <div class="flex-center">
                  <div class="roll">
                    <img src="../assets/images/home/img4.png" />
                  </div>
                </div>
                <div class="text1">{{ $t("全渠道ERP") }}</div>
              </div>
              <div class="item">
                <div class="flex-center">
                  <div class="roll">
                    <img src="../assets/images/home/img5.png" />
                  </div>
                </div>
                <div class="text1">{{ $t("大数据分析") }}</div>
              </div>
              <div class="item">
                <div class="flex-center">
                  <div class="roll">
                    <img src="../assets/images/home/img6.png" />
                  </div>
                </div>
                <div class="text1">{{ $t("客服工具") }}</div>
              </div>
            </div>
            <div class="bottom">{{ $t("运营工具") }}</div>
          </div>
          <div class="items green">
            <div class="top">
              <div class="item">
                <div class="flex-center">
                  <div class="roll">
                    <img src="../assets/images/home/img7.png" />
                  </div>
                </div>
                <div class="text1">{{ $t("二维码生成") }}</div>
              </div>
              <div class="item">
                <div class="flex-center">
                  <div class="roll">
                    <img src="../assets/images/home/img8.png" />
                  </div>
                </div>
                <div class="text1">{{ $t("生意精灵") }}</div>
              </div>
              <div class="item">
                <div class="flex-center">
                  <div class="roll">
                    <img src="../assets/images/home/img9.png" />
                  </div>
                </div>
                <div class="text1">{{ $t("快递打印") }}</div>
              </div>
            </div>
            <div class="bottom">{{ $t("辅助工具") }}</div>
          </div>
        </div>
        <div class="title" id="Development">{{ $t("开放平台") }}</div>
        <div class="title-text">
          {{ $t("完善的底层架构，低成本助力企业数字化定制") }}
        </div>
        <div class="terrace-box">
          <div class="item">
            <div class="big-font">{{ $t("DevOps") }}</div>
            <div class="text-box">
              <div>{{ $t("镜像仓库") }}</div>
              <div>{{ $t("可视化部署") }}</div>
              <div>{{ $t("灰度发布") }}</div>
              <div>{{ $t("代码安全扫描") }}</div>
            </div>
          </div>
          <div class="item">
            <div class="big-font">{{ $t("平台发布") }}</div>
            <div class="text-box">
              <div>{{ $t("前端框架") }}</div>
              <div>{{ $t("项目管理方案") }}</div>
              <div>{{ $t("应用接入SDK") }}</div>
              <div>{{ $t("可视化开发工具") }}</div>
            </div>
          </div>
          <div class="item">
            <div class="big-font">{{ $t("大数据&人工智能") }}</div>
            <div class="text-box">
              <div>{{ $t("智能推荐") }}</div>
              <div>{{ $t("智能选址") }}</div>
              <div>{{ $t("企业画像") }}</div>
              <div>{{ $t("图像分析") }}</div>
              <div>{{ $t("语音识别") }}</div>
            </div>
          </div>
          <div class="item">
            <div class="big-font">{{ $t("监控和日志") }}</div>
            <div class="text-box">
              <div>{{ $t("系统服务监控") }}</div>
              <div>{{ $t("日志存储和分析") }}</div>
              <div>{{ $t("系统服务日志") }}</div>
              <div>{{ $t("异常通知") }}</div>
            </div>
          </div>
          <div class="item">
            <div class="big-font">{{ $t("安全") }}</div>
            <div class="text-box">
              <div>{{ $t("DDoS防护") }}</div>
              <div>{{ $t("Web应用防火墙") }}</div>
              <div>{{ $t("数据加密服务") }}</div>
              <div>{{ $t("样本智能分析平台") }}</div>
              <div>{{ $t("云防火墙") }}</div>
            </div>
          </div>
        </div>
        <div class="kubernetes-box">
          <div class="item">
            <div class="left">
              <img src="../assets/images/home/icon1.png" />
            </div>
            <div class="right">
              <div class="text1">{{ $t("网络") }}</div>
              <div>{{ $t("主干道网络保障通讯") }}</div>
            </div>
          </div>
          <div class="item flex-center">
            <div class="center">
              <div class="text2">{{ $t("kubernetes集群") }}</div>
            </div>
          </div>
          <div class="item">
            <div class="left">
              <img src="../assets/images/home/icon2.png" />
            </div>
            <div class="right">
              <div class="text1">{{ $t("存储") }}</div>
              <div>{{ $t("多种不同类型数据") }}</div>
            </div>
          </div>
        </div>
        <div class="devOps-out-box">
          <div class="flex-center">
            <div class="up"></div>
          </div>
          <div class="devOps-box">
            <div class="item">
              <div class="left">
                <img src="../assets/images/home/icon3.png" />
              </div>
              <div class="right">
                <div class="text1">{{ $t("kubernets 容器云") }}</div>
                <div>{{ $t("aPaaS 平台") }}</div>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <img src="../assets/images/home/icon4.png" />
              </div>
              <div class="right">
                <div class="text1">{{ $t("一站式DevOps") }}</div>
                <div>{{ $t("提升研发效能") }}</div>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <img src="../assets/images/home/icon5.png" />
              </div>
              <div class="right">
                <div class="text1">{{ $t("高可靠，搞可用的") }}</div>
                <div>{{ $t("开放API服务") }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="title" id="Service">{{ $t("服务体系") }}</div>
        <div class="service-box">
          <div class="top">
            <div class="item">
              <div class="text1">
                {{
                  $t(
                    "具备成熟的网站桂花策略与开发技术，为客户提供全方位高端网站建设服务。"
                  )
                }}
              </div>
              <div class="text2 mt">{{ $t("网站建设") }}</div>
            </div>
            <div class="item">
              <div class="text1">
                {{
                  $t(
                    "以HTML5技术为基础，实现移动端自动兼容与游览，衍生品牌和用户之间的触点。"
                  )
                }}
              </div>
              <div class="text2 mt">{{ $t("HRML5响应式网站") }}</div>
            </div>
            <div class="item">
              <div class="text1">
                {{
                  $t(
                    "将数据做成简洁、生动的图标，凭借丰富的交互效果，洞察具价值的信息和规律。"
                  )
                }}
              </div>
              <div class="text2 mt">{{ $t("大数据可视化") }}</div>
            </div>
          </div>
          <div class="center">
            <div v-for="item in 5" :key="item">{{ item }}</div>
          </div>
          <div class="bottom">
            <div class="item">
              <div class="text2">{{ $t("电子商务解决方案") }}</div>
              <div class="text1 mt">
                {{
                  $t(
                    "以企业商化的战略发展为出发点，提供多种商业模式的电商平台建设服务。"
                  )
                }}
              </div>
            </div>
            <div class="item">
              <div class="text2">{{ $t("平台整体解决方案") }}</div>
              <div class="text1 mt">
                {{
                  $t(
                    "根据品牌及业务需求，链接行业上下游产业资源，打造一体化的服务平台。"
                  )
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copy-bottom">
      ©{{ today }} TXT Singapore,All Rights Reserved.
    </div>
  </div>
</template>

<script>
import i18n from "../i18n";
export default {
  name: "home",
  data() {
    return {
      tag: 1,
      lang: localStorage.getItem("lang") || "cn",
      langs: {
        0: "cn",
        1: "en",
      },
      langShow: false,
      today: "",
    };
  },
  mounted() {
    this.today = this.getCurrentDate();
  },
  methods: {
    langChange(lang) {
      localStorage.setItem("lang", lang);
      this.lang = lang;
      i18n.locale = lang;
      this.langShow = false;
    },
    goTo(value) {
      this.tag = value;
    },
    getCurrentDate() {
      let now = new Date();
      let year = now.getFullYear();

      return year;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import "./index.less";
</style>
