const files = require.context('./', false, /\.js$/);
const messages = {  "cn": {},"en": {}};
const langAry = Object.keys(messages);

files.keys().map(key => {
    const module = files(key).default;
    let temp;
    for (let va in module) {
      for (let i = 0; i < langAry.length; i++) {
        temp = module[va][langAry[i]];
        if (temp) {
          messages[langAry[i]][va] = temp;
        }else{
          messages[langAry[i]][va] = va;
        }
      }
    }
  });
  export default messages;